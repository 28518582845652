// Models
import { TPaymentMethod } from 'models'
import { TPaymentPayload } from 'filters/financial/models'

// React
import { FC, useLayoutEffect } from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { athleteModalPaymentRegisterSchema } from 'schemas'
import { preparePaymentToPayload } from 'filters/financial'

// Components
import { Chip, DatePicker, InputText } from 'heeds-ds'
import { Modal } from 'components'

// Constants
import { PAYMENT_TYPE_MODAL_OPTIONS } from 'utils/constants'
import { IInstallment } from 'services/financial/@types'

const DEFAULT_VALUES: IFormInputs = {
  date: '',
  method: 'pix',
  value: '0',
}

interface IFormInputs {
  date: string
  method: TPaymentMethod
  value: string
}

type Props = {
  onConfirm?: (payload: TPaymentPayload) => void
  installment?: IInstallment
}

const ModalPayment: FC<Props> = ({ onConfirm, installment }) => {
  const methods = useForm<IFormInputs>({
    defaultValues: {
      ...DEFAULT_VALUES,
      value: String(installment?.value ?? 0),
    },
    delayError: 800,
    mode: 'onChange',
    resolver: yupResolver(athleteModalPaymentRegisterSchema),
    reValidateMode: 'onChange',
  })
  const { reset, handleSubmit, watch, setValue } = methods

  const formValues = watch()

  const onSubmit = (formData: IFormInputs) => {
    const payload = preparePaymentToPayload(formData)

    onConfirm?.(payload)
  }

  useLayoutEffect(() => {
    reset({ value: String(installment?.value ?? 0) })
  }, [reset, installment?.value])

  return (
    <Modal
      title="Pagamento"
      description="Escolha a forma do pagamento e o valor do aluno."
      primaryButton={{
        name: 'Confirmar pagamento',
        onClick: handleSubmit(onSubmit),
      }}
      secondaryButton={{
        name: 'Cancelar',
      }}
      maxWidth="530px"
      showExit
    >
      <FormProvider {...methods}>
        <form
          className="flex flex-col px-6 py-4"
          data-testid="modal-payment-container"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-wrap gap-2 pb-4 pt-2">
            {PAYMENT_TYPE_MODAL_OPTIONS.map((item) => {
              return (
                <Chip
                  key={item.label}
                  active={formValues.method === item.value}
                  label={item.label}
                  onClick={() =>
                    setValue('method', item.value as TPaymentMethod)
                  }
                />
              )
            })}
          </div>

          <div className="flex gap-4">
            <InputText
              displayError={false}
              label="Valor da Mensalidade"
              margin="0"
              mask="currency"
              name="value"
              scale="small"
            />

            <DatePicker
              displayError={false}
              label="Data do Pagamento"
              margin="0"
              name="date"
              scale="small"
            />
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default ModalPayment
