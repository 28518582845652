// Models
import { FieldType } from 'components/WorkoutSetFormTag'
import { ISetToExerciseInput } from 'models'

// React
import { FC, Fragment, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useFormContext } from 'react-hook-form'

// Misc
import { useMediaQuery } from 'hooks'

// Components
import { CustomFieldMenu } from './styled'
import { DropdownMenu, IconButton } from 'heeds-ds'
import GenericInput from '../GenericInput'

// Constants
import { WORKOUT_SET_FIELD_OPTIONS } from 'utils/constants'

interface Props {
  addOrRemoveCustomField: (
    field: FieldType,
    index: number,
    remove?: boolean,
  ) => void
  index: number
  invalidInputs: Array<string>
  name: string
  readonly?: boolean
}

const CustomFields: FC<Props> = ({
  addOrRemoveCustomField,
  index,
  invalidInputs,
  name,
  readonly,
}) => {
  const { breakpoints } = useContext(ThemeContext)
  const { watch } = useFormContext()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)

  const prefix = `${name}.exercises.${index}`

  const exerciseFields: ISetToExerciseInput = watch(prefix)

  const validOptions = Object.values(WORKOUT_SET_FIELD_OPTIONS)
    .filter(
      ({ value }) =>
        exerciseFields?.[value as keyof ISetToExerciseInput] === undefined,
    )
    .map(({ label, value }) => ({
      label,
      onClick: () => addOrRemoveCustomField(value as FieldType, index),
    }))

  const customFields = Object.keys(exerciseFields ?? {})?.filter(
    (field) =>
      !invalidInputs.includes(field) &&
      exerciseFields[field as FieldType] !== undefined,
  )

  const addButton = validOptions.length ? (
    <CustomFieldMenu>
      <DropdownMenu items={validOptions}>
        <IconButton iconName="add" className={isDesktop ? 'mt-6' : ''} />
      </DropdownMenu>
    </CustomFieldMenu>
  ) : null

  return (
    <Fragment>
      {customFields?.map((field, fieldIndex) => {
        const onRemove = () =>
          addOrRemoveCustomField(field as FieldType, index, true)

        return (
          <GenericInput
            key={fieldIndex}
            onRemove={onRemove}
            prefix={prefix}
            readonly={readonly}
            type={field as FieldType}
          />
        )
      })}

      {!readonly && addButton}
    </Fragment>
  )
}

export default CustomFields
