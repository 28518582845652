// Models
import IStore from 'lib/redux/models'
import { IExerciseData, IExerciseState } from 'storage/exercise/models'
import { IWorkoutListModelPayload } from 'services/workoutModel/@types'

// React
import { FC, useCallback, useContext, useEffect, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { useDebounceFunction, useFilters, useMediaQuery, useModal } from 'hooks'
import {
  triggerDeleteExercise,
  triggerLoadExercises,
  triggerLoadMoreExercises,
} from 'storage/exercise/duck'

// Components
import * as Styled from './styled'
import { CommonList, InputSearch, Loading } from 'heeds-ds'
import {
  LibraryExerciseItem,
  LibraryHeader,
  ModalExerciseImage,
  LoaderLibraryExerciseItem,
  Modal,
  ModalNewExercise,
} from 'components'

const COLUMNS_GRID =
  'minmax(7.5rem, 0.1fr) minmax(15.25rem, 0.29fr) repeat(2,minmax(15.25rem, 0.21fr)) minmax(9.625rem, 0.13fr) minmax(6rem, 0.1fr)'

const COLUMNS_HEADERS = [
  'Imagem',
  'Nome do modelo',
  'Grupo muscular',
  'Equipamentos',
  'Data de criação',
  'Opções',
]

const page_size = 20

const LibraryExerciseTemplate: FC = () => {
  const { exercises, loading } = useSelector<IStore, IExerciseState>(
    (state) => state.exercise,
  )
  const { filters, setFilter } = useFilters<IWorkoutListModelPayload>(
    window.location.search,
  )
  const { breakpoints } = useContext(ThemeContext)
  const { openModal, isVisible, closeModal } = useModal()

  const dispatch = useDispatch()
  const isTablet = useMediaQuery(`(min-width: ${breakpoints.tablet}px)`)

  const [selected, setSelected] = useState<IExerciseData>()

  const handleEndReached = () => {
    if (exercises && !loading && exercises?.next) {
      dispatch(
        triggerLoadMoreExercises({
          ...filters,
          page: exercises.next,
          page_size,
        }),
      )
    }
  }

  const renderModelItem = useCallback(
    (item: IExerciseData) => {
      const handleEdit = () => {
        setSelected(item)
        openModal('modal-create-new-exercise')
      }

      const handleDelete = () => {
        setSelected(item)
        openModal('delete-modal')
      }

      const openExerciseGifModal = () => {
        setSelected(item)
        openModal('mobile-exercise-gif-modal-list')
      }

      return (
        <LibraryExerciseItem
          exerciseTemplate={item}
          onClick={handleEdit}
          onRemove={handleDelete}
          onClickImage={openExerciseGifModal}
          onEdit={handleEdit}
        />
      )
    },
    [openModal],
  )

  const handleReloadAthletes = useCallback(
    (newFilters: IWorkoutListModelPayload) => {
      const query = {
        ...newFilters,
        page_size,
      }
      dispatch(triggerLoadExercises(query))
    },
    [dispatch],
  )

  const debouncedLoad = useDebounceFunction<
    (newFilters: IWorkoutListModelPayload) => void
  >(handleReloadAthletes, 1000)

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filters = setFilter('name', event.target.value, true)
    debouncedLoad(filters)
  }

  const handleDeleteExercise = () => {
    closeModal()
    setSelected(undefined)
    dispatch(
      triggerDeleteExercise({
        id: selected?.id || 0,
      }),
    )
  }

  useEffect(() => {
    dispatch(triggerLoadExercises({ page_size, library: true }))
  }, [dispatch])

  if (loading) {
    return <Loading active />
  }

  return (
    <Styled.Container>
      <LibraryHeader page="Exercícios">
        <InputSearch
          placeholder="Pesquisar"
          scale="small"
          onChange={onSearchChange}
          value={filters?.name || ''}
        />
      </LibraryHeader>
      {exercises?.count ? (
        <CommonList<IExerciseData>
          bottomDistance="7.2rem"
          columns={COLUMNS_HEADERS}
          columnsFormat={COLUMNS_GRID}
          data={exercises?.results}
          hasMore={!!exercises?.next}
          loaderComponent={<LoaderLibraryExerciseItem />}
          onEndReached={handleEndReached}
          padding="1.6rem 1.6rem 1.6rem 2.4rem"
          renderItem={renderModelItem}
          rowsGap="0"
          showHeader={isTablet}
        />
      ) : (
        <Styled.EmptyContainer>
          <Styled.EmptyText>
            Você ainda não cadastrou nenhum exercício novo.
          </Styled.EmptyText>
        </Styled.EmptyContainer>
      )}
      {isVisible === 'delete-modal' && (
        <Modal
          icon="delete"
          title="Excluir exercicio"
          description="Você está excluindo um exercicio criado por você. Deseja prosseguir?"
          onClose={() => setSelected(undefined)}
          primaryButton={{
            name: 'Sim, quero excluir',
            onClick: handleDeleteExercise,
          }}
          secondaryButton={{ name: 'Voltar' }}
          maxWidth="530px"
        />
      )}
      {isVisible === 'mobile-exercise-gif-modal-list' && (
        <ModalExerciseImage exercise={selected} handleClose={closeModal} />
      )}
      {isVisible === 'modal-create-new-exercise' && (
        <ModalNewExercise
          exercise={selected}
          onClose={() => setSelected(undefined)}
        />
      )}
    </Styled.Container>
  )
}

export default LibraryExerciseTemplate
