// Models
import { IAthleteOutletContext } from 'layouts/AthleteRegisterLayout'
import { IAthletePlansState } from 'storage/athletePlans/models'
import { IAuthState } from 'storage/auth/models'
import { IFinancialState } from 'storage/financial/models'
import { IProfileState } from 'storage/profile/models'
import { TPlanModel, TPlanPeriodicity, TPlanType, TWeekdays } from 'models'
import IStore from 'lib/redux/models'

// React
import { FC, useEffect, useLayoutEffect, useMemo } from 'react'

// Libraries
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { athleteFinancialSchema } from 'schemas'
import { cleanup, triggerLoadAthletePlans } from 'storage/athletePlans/duck'
import { getPersonalProfile } from 'storage/profile/duck'
import {
  prepareAthleteFinancialToDisplay,
  prepareAthleteFinancialToPayload,
} from 'filters/athlete'
import {
  triggerCreateFinancialSubscription,
  triggerUpdateFinancialSubscription,
} from 'storage/financialSubscription/duck'
import { triggerLoadProfileExtraPlans } from 'storage/financial/duck'
import { urls } from 'routes/paths'

// Components
import * as Blocks from 'blocks/dashboard/athlete/AthleteFinancial'
import * as Styled from './styled'
import { FooterActionButton, NewContentBox } from 'components'
import { Loading } from 'heeds-ds'

export interface IFrequency {
  label: string
  value: string
  planID?: number
}

export interface IPlan {
  label: string
  value?: string
  planID?: number
  type?: TPlanType
  model?: TPlanModel
  periodicity: TPlanPeriodicity
  price?: number
  frequency?: string
}

export interface IAthleteFinancialInputs {
  id: number
  athlete?: number
  plan_type?: TPlanType
  plan_model?: TPlanModel
  plan_periodicity?: TPlanPeriodicity
  frequency_hour?: number
  start_date?: string
  due_date_day?: string
  due_date_date?: string
  extra_workouts?: string[]
  value?: number
  discount?: number
  autoRenovation: boolean
  instalmentPayment: string
  frequency: string
  selected_plan?: IPlan
}

export interface IExtraWorkout {
  id: number
  active: boolean
  name: string
  location: string
  cost: number
  days: TWeekdays[]
  start_time: string
  end_time: string
}

// TODO: REFATORAR AQUI USAR MELHOR HOOK FORM
const AthleteFinancial: FC = () => {
  const { extraPlans } = useSelector<IStore, IFinancialState>(
    (state) => state.financial,
  )
  const { plans, loading } = useSelector<IStore, IAthletePlansState>(
    (state) => state.athletePlans,
  )
  const { profile } = useSelector<IStore, IProfileState>(
    (state) => state.profile,
  )
  const { userData } = useSelector<IStore, IAuthState>(({ auth }) => auth)
  const { state } = useLocation()

  const { id, blockNavigation } = useOutletContext<IAthleteOutletContext>()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formMethods = useForm<IAthleteFinancialInputs>({
    resolver: yupResolver(athleteFinancialSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    delayError: 800,
  })
  const {
    watch,
    handleSubmit,
    reset,
    formState: { isValid },
  } = formMethods

  const extraWorkouts = watch('extra_workouts')

  const extraWorkoutValues = useMemo(
    () =>
      extraWorkouts
        ?.map((extra) => {
          return JSON.parse(extra).price
        })
        .reduce((acc, value) => acc + value, 0),
    [extraWorkouts],
  )

  const onSubmit = (data: IAthleteFinancialInputs) => {
    const payload = prepareAthleteFinancialToPayload(
      data,
      id,
      userData?.profileId || 0,
    )

    if (plans === undefined) {
      dispatch(triggerCreateFinancialSubscription(payload))
    } else {
      dispatch(triggerUpdateFinancialSubscription(payload))
    }

    state?.planEdit && navigate(-1)
  }

  useLayoutEffect(() => {
    if (!profile) {
      dispatch(getPersonalProfile())
    }
    if (!extraPlans) {
      userData?.profileId &&
        dispatch(
          triggerLoadProfileExtraPlans({ profile_pk: userData?.profileId }),
        )
    }
  }, [dispatch, extraPlans, profile, userData?.profileId])

  useLayoutEffect(() => {
    if (plans === undefined) {
      dispatch(triggerLoadAthletePlans({ id: parseInt(id) }))
    }
  }, [dispatch, id, plans])

  useEffect(() => {
    if (plans) {
      const data: IAthleteFinancialInputs =
        prepareAthleteFinancialToDisplay(plans)
      reset(data)
    }
  }, [dispatch, plans, reset])

  useEffect(() => {
    return () => {
      dispatch(cleanup())
    }
  }, [dispatch])

  useEffect(() => {
    state?.planEdit && blockNavigation(urls.athleteFinancial)
  }, [blockNavigation, state?.planEdit])

  if (loading) {
    return <Loading active />
  }

  const blocks = [
    {
      id: 0,
      title: 'Planos disponíveis',
      subtitle:
        'Selecione o tipo de plano para o seu aluno, o valor total aparecerá para você no final da tela.',
      block: <Blocks.Plans />,
    },
    {
      id: 2,
      title: 'Detalhes do plano',
      subtitle:
        'Defina quantidade de aulas, a data de início do plano e também a data de vencimento do pagamento.',
      block: <Blocks.PlanDates />,
    },
    {
      id: 3,
      title: 'Valores',
      subtitle: 'Valor total',
      block: <Blocks.Values extraWorkoutPrice={extraWorkoutValues} />,
    },
  ]

  return (
    <FormProvider {...formMethods}>
      <Styled.FormContainer onSubmit={handleSubmit(onSubmit)}>
        {blocks.map((block, index) => {
          const showExtraPLans = Boolean(extraPlans?.length)

          return block.title === 'Treinos extras' && !showExtraPLans ? null : (
            <NewContentBox
              key={block.id}
              title={block.title}
              margin={`0 0 ${index === 2 ? 0 : 3.2}rem`}
              description={block.subtitle}
            >
              {block.block}
            </NewContentBox>
          )
        })}

        {state?.planEdit ? (
          <FooterActionButton
            disabled={!isValid}
            loading={loading}
            primaryTitleButton="Salvar"
          />
        ) : (
          <FooterActionButton
            twoButtons
            disabled={!isValid}
            loading={loading}
            primaryTitleButton="Salvar"
            secundaryTitleButton="Voltar"
            onClick={() => navigate(-1)}
          />
        )}
      </Styled.FormContainer>
    </FormProvider>
  )
}

export default AthleteFinancial
