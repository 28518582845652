// Models
import { TMenuDropdownOption } from 'heeds-ds/src/models'
import { IExerciseData } from 'storage/exercise/models'

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { dateFormatEnglishToPortuguese } from 'utils/functions'
import { capitalizeFirstLetter } from 'utils/helpers/string'

// Components
import * as Styled from './styled'
import { DropdownMenu, IconButton } from 'heeds-ds'
import { EMuscleGroupToPortuguese } from 'models'

export type Props = {
  exerciseTemplate: IExerciseData
  onClick?: (modelID: string) => void
  onEdit?: (modelID: string) => void
  onClickImage?: (exercise: IExerciseData) => void
  onRemove?: () => void
}

const Desktop: FC<Props> = ({
  exerciseTemplate,
  onClick,
  onEdit,
  onRemove,
  onClickImage,
}) => {
  const { id, name, body_part, equipment, date, thumbnail } = exerciseTemplate
  const { colors } = useContext(ThemeContext)

  const options: TMenuDropdownOption[] = [
    {
      icon: 'edit',
      label: 'Editar modelo',
      onClick: () => onEdit?.(String(id)),
    },
    {
      color: colors.text.critical,
      icon: 'delete',
      label: 'Excluir',
      onClick: () => onRemove?.(),
      disabled: name === 'Model modelo',
    },
  ]

  const handleClickImage = (event: React.MouseEvent) => {
    event.stopPropagation()
    onClickImage?.(exerciseTemplate)
  }

  return (
    <Styled.Row data-testid="row" onClick={() => onClick?.(String(id))}>
      <Styled.Cell data-testid="columnThumbnail">
        <Styled.Media onClick={handleClickImage} thumbnail={thumbnail}>
          <Styled.PlayIcon size={24} />
        </Styled.Media>
      </Styled.Cell>

      <Styled.Cell data-testid="columnName">
        <Styled.Text>{capitalizeFirstLetter(name)}</Styled.Text>
      </Styled.Cell>

      <Styled.Cell data-testid="columnMuscleGroup">
        <Styled.Text>
          {
            EMuscleGroupToPortuguese[
              body_part as keyof typeof EMuscleGroupToPortuguese
            ]
          }
        </Styled.Text>
      </Styled.Cell>

      <Styled.Cell data-testid="columnEquipment">
        <Styled.Text>{capitalizeFirstLetter(equipment)}</Styled.Text>
      </Styled.Cell>

      <Styled.Cell data-testid="columnDate">
        <Styled.Text>{dateFormatEnglishToPortuguese(date)}</Styled.Text>
      </Styled.Cell>

      <Styled.Cell onClick={(e) => e.stopPropagation()}>
        <DropdownMenu items={options}>
          <IconButton
            size="small"
            iconName="moreVertical"
            track={buttonClickTracking}
            trackName="open_model_model_options"
          />
        </DropdownMenu>
      </Styled.Cell>
    </Styled.Row>
  )
}

export default Desktop
