// Models
import { IBaseState } from 'lib/redux/models'
import { IPaginatedResponse } from 'models/api'
import {
  IEquipmentResponse,
  IMuscleResponse,
  IPresignedData,
} from 'services/exercise/@types'

export enum EExerciseActionTypes {
  CLEANUP = 'EXERCISE/CLEANUP',
  CREATE_EXERCISE = 'EXERCISE/CREATE_EXERCISE',
  DELETE_EXERCISE = 'EXERCISE/DELETE_EXERCISE',
  FAILURE = 'EXERCISE/FAILURE',
  FULFILL = 'EXERCISE/FULFILL',
  GET_EQUIPMENTS = 'EXERCISE/GET_EQUIPMENTS',
  GET_EXERCISE = 'EXERCISE/GET_EXERCISE',
  GET_EXERCISES = 'EXERCISE/GET_EXERCISES',
  GET_MORE_EXERCISES = 'EXERCISE/GET_MORE_EXERCISE',
  GET_MUSCLE = 'EXERCISE/GET_MUSCLE',
  PARTIAL_UPDATE_EXERCISE = 'EXERCISE/PARTIAL_UPDATE_EXERCISE',
  REMOVE_FROM_STATE = 'EXERCISE/REMOVE_FROM_STATE',
  REQUEST = 'EXERCISE/REQUEST',
  SUCCESS = 'EXERCISE/SUCCESS',
  SUCCESS_LOAD_MORE = 'EXERCISE/SUCCESS_LOAD_MORE',
  UPDATE_EXERCISE = 'EXERCISE/UPDATE_EXERCISE',
  SUCCESS_ADD_TO_STATE = 'EXERCISE/SUCCESS_ADD_TO_STATE',
}

export interface IExerciseState extends IBaseState {
  exercises?: IExerciseListResponse
  exercise?: IExerciseData
  equipments?: IEquipmentResponse[]
  muscles?: IMuscleResponse[]
}

export interface IExerciseListPayload {
  muscle?: string
  name?: string
  body_part?: string | string[]
  page?: number
  page_size?: number
  library?: boolean
}

export interface IExerciseListResponse extends IPaginatedResponse {
  results: IExerciseData[]
}

export interface IExerciseData {
  id: number
  name: string
  file?: string | File | Blob
  muscle: string
  body_part: string
  equipment: string
  thumbnail?: string
  date?: string
  personal_profile?: number
  equipment_id?: number
  muscle_id?: number
  file_extension?: string
  pre_signed_url?: IPresignedData
}
