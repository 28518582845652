// Models
import { IFinancialExtraPlan } from 'services/financial/@types'
import { TPlanPeriodicity } from 'models'
import { IFormFields } from 'views/dashboard/profile/ProfileSchedule'
import { IFormInputs as IProfileGeneralInputs } from 'views/dashboard/profile/ProfileGeneral'
import {
  IAppointmentTime,
  IFinancialPlan,
  IPersonalData,
} from 'storage/profile/models'
import { IPlansForm } from 'views/dashboard/profile/ProfilePlans'

// Misc
import { BRL } from 'lib/currency'
import {
  dateFormatEnglishToPortuguese,
  dateFormatPortugueseToEnglish,
  generateScheduleResetObject,
  mergeCref,
  splitCref,
} from 'utils/functions'

export const preparePlansToDisplay = (plans: IFinancialPlan[]) => {
  const planMap = plans?.reduce((result: IPlansForm, plan: IFinancialPlan) => {
    const filteredCustomValue = Object.values(plan?.value?.custom || [])?.map(
      (value) => {
        const formatted = BRL(value.toString() || '').format()
        return formatted.replace('R$ 0,00', '')
      },
    )
    const prepared = {
      [plan.plan_type === 'hourly_rate'
        ? 'global'
        : `${plan.plan_periodicity}`]: {
        [plan.plan_type === 'hourly_rate' ? 'online' : plan.plan_model]:
          plan.plan_model === 'online' || plan.plan_type === 'hourly_rate' || ''
            ? {
                id: plan.id,
                active: plan.value ? true : false,
                status: plan.status,
                has_subscriptions: plan.has_subscriptions,
                has_active_subscriptions: plan.has_active_subscriptions,
                value: plan?.value,
              }
            : {
                id: plan.id,
                active: true,
                status: plan.status,
                has_subscriptions: plan.has_subscriptions,
                has_active_subscriptions: plan.has_active_subscriptions,
                value: { custom: filteredCustomValue },
              },
      },
    }

    const key = Object.keys(prepared)[0] as keyof typeof result
    if (result[key]) {
      result[key] = {
        ...result[key],
        ...prepared[key],
      }
    } else {
      result[key] = prepared[key]
    }

    return result
  }, {})

  return planMap
}

export const preparePlansToPayload = (data: IPlansForm) => {
  type ExtendedPlanPeriodicity = TPlanPeriodicity | 'global'
  const planType: ExtendedPlanPeriodicity[] = [
    'monthly',
    'quarterly',
    'semiannual',
    'yearly',
    'global',
  ]
  const plansPayload: IFinancialPlan[] = planType.reduce(
    (result: IFinancialPlan[], type) => {
      const typeOfData = type as keyof typeof data
      const inPersonPlan: IFinancialPlan = {
        id: data[typeOfData]?.in_person?.id,
        plan_type: 'custom',
        plan_model: 'in_person',
        plan_periodicity: type as TPlanPeriodicity,
        status: data[typeOfData]?.in_person?.status || 'active',
        value: {
          custom: !data[typeOfData]?.in_person?.active
            ? undefined
            : data[typeOfData]?.in_person?.value?.custom.reduce(
                (acc: { [key: string]: number }, val, index: number) => {
                  acc[index + 1] = BRL(val).value
                  return acc
                },
                {},
              ),
        },
      }

      const onlinePlan: IFinancialPlan = {
        id: data[typeOfData]?.online?.id,
        plan_type: type === 'global' ? 'hourly_rate' : 'default',
        plan_model: type === 'global' ? 'in_person' : 'online',
        plan_periodicity: type === 'global' ? 'monthly' : type,
        status: data[typeOfData]?.online?.status || 'active',
        value: {
          standard:
            data[typeOfData]?.online?.value?.standard !== '' &&
            data[typeOfData]?.online?.active
              ? BRL(data[typeOfData]?.online?.value?.standard).value
              : undefined,
        },
      }

      const newPlans = [inPersonPlan, onlinePlan].filter(
        (plan: IFinancialPlan) => {
          return (
            plan?.value?.custom !== undefined ||
            plan?.value?.standard !== undefined
          )
        },
      )

      return result.concat(newPlans)
    },
    [],
  )

  return [plansPayload]
}

export const prepareGeneralProfileToDisplay = (profile: IPersonalData) => {
  const { birthdate, crefs } = profile
  let crefSplitted = ['', '']
  if (crefs?.length) {
    const mostRecentCref = crefs[crefs.length - 1]?.register
    crefSplitted = splitCref(mostRecentCref)
  }
  return {
    name: profile.name,
    cref: crefSplitted[0],
    cref_UF: crefSplitted[1],
    birthdate: birthdate ? dateFormatEnglishToPortuguese(birthdate) : '',
    address: profile.address || '',
    cep: profile.cep || '',
    city: profile.city || '',
    complement: profile.complement || '',
    email: profile.email || '',
    gender: profile.gender,
    instagram: profile.instagram || '',
    phone_number: profile.phone_number || '',
    state: profile.state || '',
  }
}

export const prepareGeneralProfileToPayload = (data: IProfileGeneralInputs) => {
  const { cref, cref_UF, birthdate, ...formData } = data
  const birthdateFormated = birthdate
    ? dateFormatPortugueseToEnglish(birthdate)
    : null
  const crefTransformed = mergeCref(cref, cref_UF)

  return {
    ...formData,
    birthdate: birthdateFormated,
    crefs: [{ register: crefTransformed }],
  }
}

export const prepareExtraPlansToDisplay = (
  data: IFinancialExtraPlan<number>[],
) => {
  const extraPlans =
    data.map((extraPlan) => {
      return {
        ...extraPlan,
        start_time: extraPlan.start_time.slice(0, 5),
        end_time: extraPlan.end_time.slice(0, 5),
        price: BRL(extraPlan.price).format(),
      }
    }) || []
  return extraPlans
}

export const prepareExtraPlansToPayload = (
  data: IFinancialExtraPlan<string>[],
) => {
  const extraPlans =
    data.map((extraPlan) => {
      return {
        ...extraPlan,
        price: BRL(extraPlan.price).value,
      }
    }) || []
  return extraPlans
}

export const prepareAppointmentTimesToPayload = (data: IFormFields) => {
  const { allDays, toggle, ...weekDays } = data

  if (toggle) {
    return (
      allDays.day?.map((weekday) => ({
        day: weekday,
        start: allDays.start,
        end: allDays.end,
      })) || []
    )
  }

  return Object.values(weekDays)?.filter(
    (appointment) => appointment.day?.length,
  )
}

export const prepareAppointmentTimesToDisplay = (
  appointmentTimes: IAppointmentTime[],
) => {
  const activeDays = appointmentTimes.filter(
    (appointmentDay) => appointmentDay.day,
  )
  const sameHour =
    activeDays.length &&
    activeDays.every(
      (day) =>
        day.start === activeDays[0].start && day.end === activeDays[0].end,
    )

  if (sameHour) {
    return {
      allDays: {
        day: activeDays.map((day) => day.day),
        start: activeDays[0].start.slice(0, 5),
        end: activeDays[0].end.slice(0, 5),
      },
      toggle: true,
    }
  }

  return { ...generateScheduleResetObject(appointmentTimes), toggle: false }
}
