// Libs
import { cva } from 'class-variance-authority'

export const buttonStyles = cva(
  [
    'flex cursor-pointer select-none items-center justify-center gap-3',
    'whitespace-nowrap border border-solid border-transparent font-roboto',
    'font-bold leading-7 no-underline outline-none transition-all',
    'rounded-[8px] px-6 py-2 duration-200 ease-in-out',
  ],
  {
    variants: {
      size: {
        xsmall: 'text-copy4',
        small: 'text-copy3',
        medium: 'text-copy2',
        large: 'text-copy1',
      },
      variation: {
        main: [
          'bg-text-secondary text-text-onPrimary hover:bg-icon-subdued',
          'focus-visible:shadow-md active:bg-action-secondaryDefault',
        ],
        outlined: [
          'border-action-secondaryDefault bg-surface-disabled text-text-secondary',
          'hover:border-icon-subdued hover:bg-surface-hovered focus-visible:shadow-md',
          'active:border-action-secondaryPressed active:bg-surface-selectedDefault',
        ],
        borderless: [
          'text-interactive hover:text-interactive-hovered',
          'focus-visible:border-interactive active:text-interactive-depressed',
        ],
        success: [
          'border-icon-success bg-icon-success text-text-onPrimary hover:border-icon-success',
          'hover:bg-icon-success focus-visible:border-icon-success focus-visible:bg-icon-success',
          'active:border-icon-success active:bg-icon-success',
        ],
      },
      cancel: {
        true: 'true',
      },
      onlyIcon: {
        true: 'true',
      },
      disabled: {
        true: 'cursor-not-allowed opacity-50',
      },
    },
    compoundVariants: [
      {
        variation: 'main',
        cancel: true,
        class: [
          'bg-interactive-critical text-text-onPrimary',
          'hover:bg-interactive-criticalHovered focus-visible:shadow-md',
          'active:bg-interactive-criticalDepressed',
        ],
      },
      {
        variation: 'outlined',
        cancel: true,
        class: [
          'border-interactive-critical bg-surface-disabled',
          'text-interactive-critical hover:border-interactive-criticalHovered',
          'hover:bg-surface-disabled focus-visible:shadow-md',
          'active:border-interactive-criticalDepressed',
          'active:bg-surface-disabled',
        ],
      },
      {
        variation: 'borderless',
        cancel: true,
        class: [
          'border-text-onPrimary text-interactive-critical',
          'hover:border-text-onPrimary hover:text-interactive-criticalHovered',
          'focus-visible:border-text-onPrimary active:border-text-onPrimary',
          'active:text-interactive-criticalDepressed',
        ],
      },
      {
        size: 'xsmall',
        onlyIcon: true,
        class: 'p-1.5',
      },
      {
        size: 'small',
        onlyIcon: true,
        class: 'p-2',
      },
      {
        size: 'medium',
        onlyIcon: true,
        class: 'p-2.5 rounded-[10px]',
      },
      {
        size: 'large',
        onlyIcon: true,
        class: 'p-3 rounded-[10px]',
      },
    ],
    defaultVariants: {
      variation: 'main',
      size: 'medium',
    },
  },
)
