// Models
import { EFirebaseProviderOptions, IRegisterPayload } from 'storage/auth/models'
import { TOutletContext } from 'layouts/AuthLayout'
import { TypeUser } from 'models'

// React
import { FC, useCallback, useContext, useLayoutEffect, useState } from 'react'

// Libraries
import { FormProvider } from 'react-hook-form'
import { ThemeContext } from 'styled-components'
import { useDispatch } from 'react-redux'
import { useNavigate, useOutletContext } from 'react-router-dom'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { authSignupSchema } from 'schemas'
import { triggerLogin, triggerRegister } from 'storage/auth/duck'
import { urls } from 'routes/paths'
import useGlobalForms from 'hooks/useGlobalForms'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import * as Styled from './styled'
import {
  Aligner,
  Body,
  Button,
  FormButton,
  GenericLink,
  InputText,
  LogoFull,
  PasswordValidator,
  SSOButton,
  Subtitle,
  Title,
} from 'heeds-ds'

// Assets
import deskBackgroundImage from 'assets/images/coaches-rafiki.png'
import mobileBackgroundImage from 'assets/images/training-at-home-rafiki.png'
import logoFullBeta from 'assets/images/logo-cadu-beta.svg'

interface IFormInputs {
  name: string
  email: string
  password: string
}

const Signup: FC = () => {
  const { setLayoutBackground, setLayoutTitles, setShowLogo } =
    useOutletContext<TOutletContext>()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const formMethods = useGlobalForms<IFormInputs>({ schema: authSignupSchema })
  const {
    handleSubmit,
    formState: { isValid },
    watch,
  } = formMethods
  const formValues = watch()

  const [passwordValid, setPasswordValid] = useState<boolean | undefined>()
  const [showPreSignup, setShowPreSignup] = useState(true)

  const submitDisabled = !isValid || !passwordValid

  const submitLogin = useCallback(
    (provider: EFirebaseProviderOptions, data?: IFormInputs) => {
      const redirect =
        new URLSearchParams(window.location.search).get('redirect') || ''
      const to = Object.values(urls).includes(redirect)
        ? redirect
        : urls.dashboard

      const payload = {
        ...data,
        provider: provider,
        redirection: { navigate, to },
        type: 'personal' as TypeUser,
      }

      dispatch(triggerLogin(payload))
    },
    [dispatch, navigate],
  )

  const onSubmit = (data: IFormInputs) => {
    if (passwordValid) {
      const to = urls.signupSuccess
      const payload: IRegisterPayload = {
        ...data,
        provider: EFirebaseProviderOptions.EMAIL,
        type: 'personal',
        redirection: { navigate, to },
        has_plans: false,
        has_appointment_time: false,
      }

      dispatch(triggerRegister(payload))
    }
  }

  useLayoutEffect(() => {
    setLayoutBackground(
      !isDesktop && showPreSignup ? mobileBackgroundImage : '',
    )
    setLayoutTitles(
      isDesktop && (
        <Aligner flex="column" gap="8px" align="center" margin="0 0 4.5rem 0">
          <Title weight={600} align="center">
            Bem-vindo ao CADU!
          </Title>
          <Subtitle type="sh3" color={theme.colors.text.subdued} align="center">
            A ferramenta que facilita a vida do Personal Trainer na gestão de
            alunos e treinos.
          </Subtitle>
          <Styled.Image
            src={
              isDesktop
                ? deskBackgroundImage
                : showPreSignup
                ? mobileBackgroundImage
                : ''
            }
          />
        </Aligner>
      ),
    )
    setShowLogo(!isDesktop && showPreSignup ? false : true)
    return () => {
      setLayoutTitles(null)
    }
  }, [
    isDesktop,
    setLayoutBackground,
    setLayoutTitles,
    setShowLogo,
    showPreSignup,
    theme,
  ])

  if (!isDesktop && showPreSignup) {
    return (
      <Aligner flex="column" align="center">
        <LogoFull src={logoFullBeta} />
        <Subtitle weight={500} margin="5.6rem 0 2.4rem">
          Junte-se ao CADU
        </Subtitle>
        <Body color={theme.colors.text.subdued} weight={400} align="center">
          A ferramenta que facilita a vida do Personal Trainer na gestão de
          alunos e treinos.
        </Body>
        <Button
          size="small"
          track={buttonClickTracking}
          trackName="create_register"
          onClick={() => setShowPreSignup(false)}
          className="mb-16 mt-14 w-full"
        >
          Criar meu cadastro
        </Button>
      </Aligner>
    )
  }

  return (
    <FormProvider {...formMethods}>
      <Styled.FormContainer onSubmit={handleSubmit(onSubmit)}>
        {!isDesktop && (
          <Subtitle weight={500} align="center" margin="0 0 2.4rem 0">
            Seja bem-vindo!
          </Subtitle>
        )}
        <Aligner
          gap="1.2rem"
          justify={isDesktop ? 'center' : 'space-between'}
          margin="0 0 3.2rem"
          padding={isDesktop ? '0 24px' : '0'}
        >
          <SSOButton
            onClick={() => submitLogin(EFirebaseProviderOptions.GOOGLE)}
            provider="google"
          />
          <SSOButton
            onClick={() => submitLogin(EFirebaseProviderOptions.FACEBOOK)}
            provider="facebook"
          />
          <SSOButton disabled provider="apple" />
        </Aligner>
        <Body
          type="copy2"
          align="center"
          color={theme.colors.text.subdued}
          weight={500}
        >
          ou use seu e-mail
        </Body>
        <InputText
          label="Nome Completo"
          placeholder="Digite seu nome"
          name="name"
          scale="small"
          margin="3.2rem 0"
        />
        <InputText
          label="Email"
          placeholder="Digite seu email"
          name="email"
          scale="small"
          type="email"
          margin="3.2rem 0"
        />
        <InputText
          label="Crie sua senha"
          type="password"
          placeholder="Digite sua senha"
          name="password"
          scale="small"
          margin="3.2rem 0 2.4rem"
        />
        <PasswordValidator
          value={formValues.password}
          callback={setPasswordValid}
        />
        <FormButton
          disabled={submitDisabled}
          size="small"
          track={buttonClickTracking}
          trackName="register_new_user"
          className="mb-6 mt-8 w-full"
        >
          Cadastrar
        </FormButton>
      </Styled.FormContainer>
      <GenericLink
        align="center"
        fontSize="1.4rem"
        to={urls.login}
        margin="0 0 5.6rem"
      >
        Já sou cadastrado
      </GenericLink>
    </FormProvider>
  )
}

export default Signup
