// Libraries
import styled from 'styled-components'
import * as NavRadix from '@radix-ui/react-navigation-menu'
import { css } from 'styled-components'

export const Wrapper = styled(NavRadix.Root)`
  align-items: end;
  list-style: none;
  display: grid;
  grid-template-rows: min-content 1fr;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    overflow-y: auto;
  }
`

export const List = styled(NavRadix.List)`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    gap: 2.4rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    > li {
      :last-child {
        margin-top: 2.4rem;
      }
    }
  }
`

export const Span = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.text.subdued};
  `}
  margin: 4px 0 0;
`

export const Br = styled.br``
