// Models
import { EPlanModelToPortuguese, EPlanPeriodicityToPortuguese } from 'models'
import { IAthleteOverview } from 'services/athleteOverview/@types'

// React
import { FC, useCallback, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { generatePath, useNavigate } from 'react-router-dom'

// Misc
import { BRL } from 'lib/currency'
import { buttonClickTracking } from 'utils/tracking'
import { urls } from 'routes/paths'
import { useMediaQuery } from 'hooks'
import momentBR from 'lib/moment'

// Components
import * as Styled from './styled'
import {
  Aligner,
  Body,
  Icon,
  IconButton,
  LinkButton,
  SimpleContainer,
} from 'heeds-ds'

type Props = {
  overview: IAthleteOverview
}

const Financial: FC<Props> = ({ overview }) => {
  const navigate = useNavigate()
  const theme = useContext(ThemeContext)
  const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  const navigateToFinancial = useCallback(
    () =>
      navigate(
        generatePath(urls.athleteFinancial, {
          id: overview.id,
        }),
      ),
    [navigate, overview.id],
  )

  if (!overview.next_installment?.value) {
    return (
      <Styled.Content>
        <SimpleContainer>
          <Styled.ContentTitle>Financeiro</Styled.ContentTitle>
          <Body
            type="copy3"
            align="center"
            margin="3.2rem auto"
            color={theme.colors.text.disabled}
          >
            Esse aluno não possui um plano ativo.
          </Body>
          <LinkButton
            size="xsmall"
            variation="borderless"
            to={generatePath(urls.athleteFinancial, { id: overview.id })}
            track={buttonClickTracking}
            trackName="athlete_profile_empty_financial_link"
            className="mx-auto my-0"
          >
            Ir para financeiro
          </LinkButton>
        </SimpleContainer>
      </Styled.Content>
    )
  }

  return (
    <Styled.Content>
      <SimpleContainer>
        <Styled.ContentTitle>Financeiro</Styled.ContentTitle>
        <Styled.FinancialValue>
          <Icon
            iconName="circleCheck"
            color={theme.colors.icon.success}
            size={20}
          />
          {BRL(overview.next_installment?.value).format()}
        </Styled.FinancialValue>
        <Body
          type="copy4"
          color={theme.colors.text.subdued}
          margin="1.6rem 0 0"
        >
          Vencimento:{' '}
          <Styled.Bold>
            {momentBR(overview.next_installment?.due_date).format(
              'DD [de] MMMM [de] YYYY',
            )}
          </Styled.Bold>
        </Body>
      </SimpleContainer>
      <Aligner
        flex={isTablet ? 'column' : 'row'}
        justify={isTablet ? 'center' : 'space-evenly'}
        align="center"
        padding={isTablet ? '0 6.2rem 0 0' : '3.2rem 0 0'}
        gap="0.8rem"
      >
        {overview.plan_model && (
          <Styled.PlanBadge>
            {EPlanModelToPortuguese[overview.plan_model]}
          </Styled.PlanBadge>
        )}
        {overview.plan_periodicity && (
          <Styled.PlanBadge>
            {EPlanPeriodicityToPortuguese[overview.plan_periodicity]}
          </Styled.PlanBadge>
        )}
      </Aligner>
      <Styled.IconWrapper>
        <IconButton
          iconName="arrowRightLong"
          size="small"
          onClick={navigateToFinancial}
          track={buttonClickTracking}
          trackName="athlete_profile_financial_link"
        />
      </Styled.IconWrapper>
    </Styled.Content>
  )
}

export default Financial
