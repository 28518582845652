// Models
import {
  EAthleteStatusToPortuguese,
  EPaymentStatusToPortuguese,
  EPlanPeriodicityToPortuguese,
} from 'models'
import { IFinancialAthleteProps } from '..'
import { TMenuDropdownOption } from 'heeds-ds/src/models'

// React
import { FC } from 'react'

// Misc
import { cn } from 'utils/helpers/classess'
import { currencyMask } from 'utils/masks'
import { dateFormatEnglishToPortuguese } from 'utils/functions'

// Components
import { Avatar, DropdownMenu, IconButton } from 'heeds-ds'

// Constants
import { STATUS_VARIATION } from '..'

interface Props extends IFinancialAthleteProps {
  options: TMenuDropdownOption[]
}

const Desktop: FC<Props> = ({
  subscription: {
    id,
    athlete_name,
    installments_next_due_date,
    payment_status,
    plan__periodicity,
    spread,
    status,
    total_price,
  },
  onClick,
  options,
}) => {
  const cellBaseClass = cn('flex flex-col justify-center gap-1 p-4')
  const spreadLabel = spread ? 'Parcelado' : 'À Vista'

  return (
    <div
      className={cn(
        'grid w-full grid-cols-financial-list border-b border-border-input',
        'text-copy4 text-text hover:cursor-pointer',
      )}
      data-testid={`financial-athlete-item-${id}`}
      onClick={onClick}
    >
      <div className="flex gap-2 p-4">
        <Avatar
          disabled={status === 'inactive'}
          name={athlete_name}
          scale="xSmall"
        />

        <div className="flex flex-col gap-1">
          <p>{athlete_name}</p>

          <p className="text-text-subdued">
            {status && EAthleteStatusToPortuguese[status]}
          </p>
        </div>
      </div>

      <div className={cellBaseClass}>
        <p>
          {plan__periodicity && EPlanPeriodicityToPortuguese[plan__periodicity]}
        </p>
      </div>

      <div className={cellBaseClass}>
        <p>{dateFormatEnglishToPortuguese(installments_next_due_date)}</p>
      </div>

      <div className={cellBaseClass}>
        {payment_status && (
          <div
            className={cn(
              'w-fit rounded-full px-4 py-2',
              STATUS_VARIATION[payment_status],
            )}
          >
            <p className={cn('text-copy5 font-semibold leading-6')}>
              {EPaymentStatusToPortuguese[payment_status]}
            </p>
          </div>
        )}
      </div>

      <div className={cellBaseClass}>
        <p>{currencyMask(`${total_price ?? 0}`)}</p>

        <p className="text-text-subdued">{spreadLabel}</p>
      </div>

      <div className={cellBaseClass} onClick={(e) => e.stopPropagation()}>
        <DropdownMenu items={options}>
          <IconButton iconName="moreVertical" size="small" />
        </DropdownMenu>
      </div>
    </div>
  )
}

export default Desktop
