// Models
import { TMenuDropdownOption } from 'heeds-ds/src/models'
import { IExerciseData } from 'storage/exercise/models'

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'

// Components
import * as Styled from './styled'
import { DropdownMenu, IconButton } from 'heeds-ds'
import { EMuscleGroupToPortuguese } from 'models'

export type Props = {
  exerciseTemplate: IExerciseData
  onEdit?: (modelID: string) => void
  onClickImage?: (exercise: IExerciseData) => void
  onRemove?: () => void
}

const Mobile: FC<Props> = ({
  exerciseTemplate,
  onEdit,
  onRemove,
  onClickImage,
}) => {
  const { colors } = useContext(ThemeContext)

  const { name, personal_profile, id, date, equipment, body_part, thumbnail } =
    exerciseTemplate

  const options: TMenuDropdownOption[] = [
    {
      icon: 'edit',
      label: 'Editar',
      onClick: () => onEdit?.(String(id)),
    },
    {
      color: colors.text.critical,
      icon: 'delete',
      label: 'Excluir',
      onClick: () => onRemove?.(),
      disabled: name === 'Model modelo',
    },
  ]

  const handleClickImage = (event: React.MouseEvent) => {
    event.stopPropagation()
    onClickImage?.(exerciseTemplate)
  }

  return (
    <Styled.Container data-testid="routine-item">
      <Styled.Media onClick={handleClickImage} thumbnail={thumbnail}>
        <Styled.PlayIcon size={48} />
      </Styled.Media>
      <Styled.TitlesContainer>
        <Styled.Title>{name}</Styled.Title>
        <Styled.Creator>{date}</Styled.Creator>
        <Styled.Creator>
          {personal_profile !== 0 ? 'Você' : 'CADU (sistema)'}
        </Styled.Creator>
        <Styled.Creator>{equipment}</Styled.Creator>
        <Styled.Creator>
          {
            EMuscleGroupToPortuguese[
              body_part as keyof typeof EMuscleGroupToPortuguese
            ]
          }
        </Styled.Creator>
      </Styled.TitlesContainer>
      <Styled.OptionsContainer>
        <DropdownMenu items={options}>
          <IconButton
            size="small"
            iconName="moreVertical"
            track={buttonClickTracking}
            trackName="open_model_model_options"
          />
        </DropdownMenu>
      </Styled.OptionsContainer>
    </Styled.Container>
  )
}

export default Mobile
