// Models
import { BodyVariationsTypes } from 'lib/styled-components/models'
import { TTailwindSpacing } from '../../models'

// Misc
import { cn } from '../../utils/classes'

// React
import { FC } from 'react'

type Props = {
  active?: string
  distanceOfBar?: TTailwindSpacing
  fontSize?: BodyVariationsTypes
  className?: string
  setActive: (tab: string) => void
  tabs: string[]
}

const Tabs: FC<Props> = ({
  active,
  distanceOfBar,
  fontSize,
  className,
  setActive,
  tabs,
}) => {
  const fontSizeClass = fontSize ? `text-${fontSize}` : 'text-copy3'
  const distanceOfBarClass = distanceOfBar ? `pb-${distanceOfBar}` : 'pb-6'

  return (
    <div
      data-testid="container"
      className={cn('w-full overflow-x-auto flex', className)}
    >
      {tabs.map((tab) => {
        const isSelected = tab === active
        return (
          <div
            key={tab}
            onClick={() => setActive(tab)}
            data-testid={`tab-${tab}`}
            className={cn(
              'cursor-pointer border-b-4 px-4 pt-6 border-white',
              distanceOfBarClass,
              {
                'border-action-secondaryDefault': isSelected,
              },
            )}
          >
            <p
              className={cn(
                'font-roboto font-bold text-text-subdued',
                fontSizeClass,
                { 'text-text': isSelected },
              )}
            >
              {tab}
            </p>
          </div>
        )
      })}
    </div>
  )
}

export default Tabs
