// Models
import IStore from 'lib/redux/models'
import { IAuthState } from 'storage/auth/models'
import { IExerciseData, IExerciseState } from 'storage/exercise/models'

// React
import { FC, useContext, useEffect, useState } from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { ExerciseSchema } from 'schemas'
import { useMediaQuery, useModal } from 'hooks'
import {
  triggerCreateExercise,
  triggerLoadEquipment,
  triggerLoadMuscle,
  triggerPartialUpdateExercise,
} from 'storage/exercise/duck'

// Components
import * as Styled from './styled'
import { Chip, InputText, Loading } from 'heeds-ds'
import { InputFile, Modal } from 'components'

type ModalNewExerciseProps = {
  exercise?: IExerciseData
  onClose?: () => void
}

const ModalNewExercise: FC<ModalNewExerciseProps> = ({ exercise, onClose }) => {
  const dispatch = useDispatch()
  const { closeModal } = useModal()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const { userData } = useSelector<IStore, IAuthState>((state) => state.auth)
  const { equipments, muscles, loading } = useSelector<IStore, IExerciseState>(
    (state) => state.exercise,
  )

  const methods = useForm<IExerciseData>({
    resolver: yupResolver(ExerciseSchema),
    delayError: 800,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const {
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { isValid },
  } = methods

  const [preview, setPreview] = useState<string>('')

  const onSubmit = (form: IExerciseData) => {
    const fileFormatted = form.file as Blob

    const payload = {
      athlete_pk: userData?.profileId || 0,
      exercise: {
        ...form,
        personal_profile: userData?.profileId,
        id: exercise?.id || 0,
        file_extension: fileFormatted?.type.split('/')[1],
      },
    }

    if (exercise) {
      dispatch(triggerPartialUpdateExercise(payload))
      handleOnCLose()
      return
    }
    dispatch(triggerCreateExercise(payload))
    handleOnCLose()
  }

  const handleOnCLose = () => {
    onClose?.()
    closeModal()
  }

  const disabledButton =
    exercise === undefined
      ? !(isValid && watch('file') !== undefined)
      : !isValid

  useEffect(() => {
    if (exercise) {
      const muscleId = muscles?.filter(
        (muscle) => muscle.name === exercise.muscle,
      )[0].id
      const equipmentId = equipments?.filter(
        (equipment) => equipment.name === exercise.equipment,
      )[0].id

      setPreview(exercise?.thumbnail || '')
      setValue('muscle_id', muscleId)
      setValue('equipment_id', equipmentId)
      setValue('name', exercise.name)
    }
  }, [equipments, exercise, muscles, reset, setValue])

  useEffect(() => {
    if (equipments === undefined) {
      dispatch(triggerLoadEquipment())
    }
  }, [dispatch, equipments])

  useEffect(() => {
    if (muscles === undefined) {
      dispatch(triggerLoadMuscle())
    }
  }, [dispatch, muscles])

  if (loading) {
    return <Loading active />
  }

  return (
    <Modal
      title="Criar exercício"
      description="Adicione um vídeo/gif, o nome e as informações necessárias para criar seu exercício na biblioteca."
      primaryButton={{
        name: 'Salvar alterações',
        type: 'submit',
        onClick: handleSubmit(onSubmit),
        disabled: disabledButton,
      }}
      secondaryButton={{ name: 'Cancelar', onClick: handleOnCLose }}
      onClose={handleOnCLose}
    >
      <FormProvider {...methods}>
        <Styled.FormContainer onSubmit={handleSubmit(onSubmit)}>
          <Styled.FormLeft>
            <InputText
              name={'name'}
              label="Nome do exercício"
              margin="0"
              scale="small"
            />
            <Styled.Label>Imagem do exercício</Styled.Label>
            <Styled.Text>
              Adicione um pequeno vídeo ou um gif. Ele será apresentado ao seu
              aluno.
            </Styled.Text>
            <Styled.FileInputContainer>
              {preview ? (
                <Styled.FileInputPreview src={preview} />
              ) : (
                <Styled.IconContainer>
                  <Styled.PlayIcon />
                </Styled.IconContainer>
              )}
              <InputFile
                setFile={(file) =>
                  setValue('file', file as File, { shouldValidate: true })
                }
                preview={(img) => setPreview(img as string)}
                accept="image/*,image/gif"
                singleFile
                size={15}
                dragText={
                  <>
                    <Styled.BlueText>
                      Clique aqui para adicionar{' '}
                    </Styled.BlueText>
                    {isDesktop && (
                      <Styled.Text>ou arraste para cá.</Styled.Text>
                    )}
                    <Styled.TextSmall align="center">
                      PNG, JPG ou JPEG (tamanho máximo de 15mb)
                    </Styled.TextSmall>
                  </>
                }
              />
            </Styled.FileInputContainer>
          </Styled.FormLeft>
          <Styled.FormRight>
            <Styled.Label>Grupo Muscular</Styled.Label>
            <Styled.FilterContainer height="160px">
              {muscles?.map((filter, index) => {
                const active = watch('muscle_id') === filter.id
                return (
                  <Chip
                    key={index}
                    label={filter.name}
                    active={active}
                    scale="small"
                    onClick={() => setValue('muscle_id', filter.id)}
                  />
                )
              })}
            </Styled.FilterContainer>
            <Styled.Label margin="16px 0 0 0">Equipamentos</Styled.Label>
            <Styled.FilterContainer height="80px">
              {equipments?.map((filter, index) => {
                const active = watch('equipment_id') === filter.id
                return (
                  <Chip
                    key={index}
                    label={filter.name}
                    active={active}
                    scale="small"
                    onClick={() => setValue('equipment_id', filter.id)}
                  />
                )
              })}
            </Styled.FilterContainer>
          </Styled.FormRight>
        </Styled.FormContainer>
      </FormProvider>
    </Modal>
  )
}

export default ModalNewExercise
