// React
import { FC, ReactNode, useContext, useMemo } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import {
  Outlet,
  generatePath,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { useDispatch } from 'react-redux'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { triggerClearWorkoutModels } from 'storage/workoutModel/duck'
import { useMediaQuery, useModal } from 'hooks'
import { urls } from 'routes/paths'

// Components
import * as Styled from './styled'
import { Aligner, Body, Button, Icon } from 'heeds-ds'

export interface IAthleteOutletContext {
  id: string
  isEditing: boolean
  navigateToNextTab: (to?: string) => void
  navigateToPreviousTab: (to?: string) => void
  blockNavigation: (to?: string) => void
}

type Props = {
  children?: ReactNode | ReactNode[]
}

const LibraryLayout: FC<Props> = ({ children }) => {
  const { breakpoints, colors } = useContext(ThemeContext)
  const { pathname } = useLocation()
  const { openModal } = useModal()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const paths = useMemo(() => {
    return [
      { path: urls.libraryAnamneseTemplates },
      { path: urls.LibraryWorkoutModels },
      { path: urls.LibraryExerciseTemplate },
    ]
  }, [])

  const buttonOption = useMemo(() => {
    return [
      {
        onClick: () => navigate(urls.libraryAnamneseCreate),
        trackName: 'navigate_to_create_new_anamnese_template',
      },
      {
        onClick: () => {
          dispatch(triggerClearWorkoutModels())
          navigate(
            generatePath(urls.libraryWorkoutModelCreateEdit, {
              id: 'novo-treino',
            }),
          )
        },
        trackName: 'navigate_to_create_new_workout',
      },
      {
        onClick: () => openModal('modal-create-new-exercise'),
        trackName: 'open_modal_crate_new_exercise',
      },
    ][paths.findIndex((step) => step.path === pathname)]
  }, [paths, navigate, dispatch, openModal, pathname])

  const renderButton = () => {
    return (
      <Styled.ButtonContainer>
        <Button
          onClick={buttonOption?.onClick}
          size="small"
          track={buttonClickTracking}
          trackName={buttonOption?.trackName}
          className="ml-6"
        >
          <Icon color={colors.text.onPrimary} iconName="add" />
          Criar modelo
        </Button>
      </Styled.ButtonContainer>
    )
  }

  return (
    <Styled.Container>
      <Aligner gap="4rem" flex="column">
        <Styled.CardHeader>
          <Styled.CardTitles>
            {isDesktop && (
              <Body weight={700} type="copy3">
                Biblioteca
              </Body>
            )}
            <Body weight={400} color={colors.text.subdued} type="copy3">
              Aqui você encontrará modelos prontos, como também poderá criar
              seus próprios treinos e avaliações para usar com seus alunos.
            </Body>
          </Styled.CardTitles>
          {renderButton()}
        </Styled.CardHeader>
      </Aligner>
      <Styled.Content>
        {children || <Outlet />}

        {!isDesktop && renderButton()}
      </Styled.Content>
    </Styled.Container>
  )
}

export default LibraryLayout
