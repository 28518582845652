// Models
import { IExerciseListPayload, IExerciseState } from 'storage/exercise/models'
import { IFormInputs, IModelInput, TMuscleGroupFilter } from 'models'
import { IWorkoutModelState } from 'storage/workoutModel/models'
import IStore from 'lib/redux/models'

// React
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'

// Libraries
import { FormProvider } from 'react-hook-form'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import isEqual from 'lodash.isequal'

// Misc
import { prepareModelsToDisplay, prepareModelsToRedux } from 'filters/workouts'
import { success, triggerGetWorkoutsModel } from 'storage/workoutModel/duck'
import {
  triggerLoadExercises,
  triggerLoadMoreExercises,
} from 'storage/exercise/duck'
import { uid } from 'utils/functions'
import { urls } from 'routes/paths'
import {
  useDebounceFunction,
  useFilters,
  useMediaQuery,
  useModal,
  useWorkoutModelsForm,
} from 'hooks'

// Components
import * as Styled from './styled'
import { IconButton, InputText, Loading } from 'heeds-ds'
import { Modal } from 'components'
import Desktop from './desktop'
import Mobile from './mobile'

// Constants
import { BODY_PART_FILTERS } from 'utils/constants'

const EMPTY_MODELS = [
  {
    id: 'NEW' + uid(),
    name: '',
    workout_set: {},
  },
]

const page_size = 20

interface IFiltersQuery extends IExerciseListPayload {
  etapa?: '1' | '2'
}

const LibraryWorkoutModelCreate: FC = () => {
  const { id = '' } = useParams()
  const { exercises, loading: loadingExercise } = useSelector<
    IStore,
    IExerciseState
  >((state) => state.exercise)
  const { loading: loadingModel, workoutModels } = useSelector<
    IStore,
    IWorkoutModelState
  >((state) => state.workoutModel)
  const { breakpoints } = useContext(ThemeContext)
  const { filters, isValueIncluded, setFilter, toggleFilter, resetFilters } =
    useFilters<IFiltersQuery>(window.location.search)
  const { isVisible, closeModal } = useModal()
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)
  const navigate = useNavigate()

  const [, setSearchParams] = useSearchParams()

  const [showFilters, setShowFilters] = useState(false)
  const [stateModels, setStateModels] = useState<IModelInput[]>(
    workoutModels && workoutModels.length > 0 ? workoutModels : EMPTY_MODELS,
  )

  const { methods, updateWorkoutModel, ...commonHookProps } =
    useWorkoutModelsForm(stateModels, setStateModels)

  const isANewWorkoutModel =
    id === 'novo-treino' && !stateModels[0].id.includes('NEW')

  const formValues = methods.watch()

  const handleGoBack = () => navigate(-1)

  const onChangeFilters = (filtersA: TMuscleGroupFilter[]) => {
    const newFilters: IFiltersQuery = {
      ...filters,
      body_part: filtersA.flatMap((filter) =>
        filter.active ? filter.name : [],
      ),
    }

    resetFilters(newFilters)

    handleReloadExercises(newFilters)
  }

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filters = setFilter('name', event.target.value, true)
    debouncedLoad(filters)
  }

  const handleEndReached = () => {
    if (exercises && !loadingExercise && exercises?.next) {
      const query = {
        ...filters,
        page: exercises.next,
        page_size,
      }
      dispatch(triggerLoadMoreExercises(query))
    }
  }

  const handleReloadExercises = (newFilters: IExerciseListPayload) => {
    const query = {
      ...newFilters,
      page_size,
    }
    dispatch(triggerLoadExercises(query))
  }

  const debouncedLoad = useDebounceFunction<
    (newFilters: IExerciseListPayload) => void
  >(handleReloadExercises, 1000)

  const onClickFilter = () => {
    setShowFilters((prev) => !prev)
  }

  const removeFilter = (label: string) => {
    const newFilters = toggleFilter('body_part', label, false)
    handleReloadExercises(newFilters)
  }

  const changePage = (page: string) => setSearchParams({ etapa: page })

  const onSubmit = useCallback(
    (formData: IFormInputs) => {
      const reduxData = prepareModelsToRedux(formData, stateModels)

      const updatedModels = reduxData.filter((model) =>
        Boolean(Object.values(model.workout_set ?? {}).length),
      )

      dispatch(success({ workoutModels: updatedModels }))

      navigate(
        generatePath(urls.libraryWorkoutModelReview, {
          id: id || 'novo-treino',
        }),
      )

      closeModal()
    },
    [closeModal, dispatch, id, navigate, stateModels],
  )

  const workoutRoutineProps = {
    ...commonHookProps,
    filters: BODY_PART_FILTERS.map((filter) => ({
      active: isValueIncluded(filter.param, filter.value.toString()) || false,
      name: filter.value,
      label: filter.label,
    })),
    inputValue: filters?.name || '',
    loadMoreExercises: handleEndReached,
    methods,
    onChangeFilters,
    onClickFilter,
    onSearchChange,
    onSubmit,
    removeFilter,
    selectedModelIndex: 0,
    showFilters,
    stateModels,
    updateWorkoutModel,
  }

  useEffect(() => {
    const payload = prepareModelsToRedux(formValues, stateModels)

    if (!isEqual(payload, workoutModels)) {
      setStateModels(payload)
      dispatch(success({ workoutModels: payload }))
    }
  }, [dispatch, formValues, setStateModels, stateModels, workoutModels])

  useLayoutEffect(() => {
    dispatch(triggerLoadExercises({ ...filters, page_size }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useLayoutEffect(() => {
    if (id === 'novo-treino') {
      if (isANewWorkoutModel) {
        setStateModels(EMPTY_MODELS)
        methods.setValue('models.0.name', '')
      }
    } else {
      stateModels[0].id !== id &&
        !loadingModel &&
        dispatch(
          triggerGetWorkoutsModel({
            model_pk: id,
            successCallback: (model) => {
              setStateModels(prepareModelsToDisplay([model]))
              methods.setValue('models.0', prepareModelsToDisplay([model])[0])
            },
          }),
        )
    }
  }, [dispatch, id, isANewWorkoutModel, loadingModel, methods, stateModels])

  if (
    loadingModel ||
    !workoutModels ||
    (id !== 'novo-treino' && workoutModels[0].id !== id)
  ) {
    return <Loading active />
  }

  return (
    <Styled.Container>
      <Styled.ContainerHeader>
        {isDesktop && (
          <IconButton
            size="large"
            iconName="arrowBack"
            onClick={handleGoBack}
          />
        )}
        <Styled.Header>
          <Styled.ModelNameLabel>Nome do modelo: </Styled.ModelNameLabel>
          <FormProvider {...methods}>
            <InputText
              displayError={false}
              margin="0"
              name="models.0.name"
              placeholder="Digite o nome do modelo de treino"
            />
          </FormProvider>
        </Styled.Header>
      </Styled.ContainerHeader>
      <Styled.Content>
        {isDesktop ? (
          <Desktop {...workoutRoutineProps} />
        ) : (
          <Mobile
            {...workoutRoutineProps}
            changePage={changePage}
            mobilePage={filters.etapa}
          />
        )}
      </Styled.Content>

      {isVisible === 'go-to-review-modal' && (
        <Modal
          title="Revisar treino"
          description="Você está indo revisar o treino criado. É possível retornar e continuar editando. Deseja prosseguir?"
          maxWidth="51rem"
          icon="info"
          onClose={closeModal}
          primaryButton={{
            name: 'Ir para revisão',
            onClick: methods.handleSubmit(onSubmit),
          }}
          secondaryButton={{
            name: 'Cancelar',
          }}
        />
      )}
    </Styled.Container>
  )
}

export default LibraryWorkoutModelCreate
