// Models
import { IWorkoutModelData } from 'storage/workoutModel/models'
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'
import { TDownloadPDFModel, TRemoveModel } from 'models'

// React
import React, { FC, useCallback, useContext, useMemo } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { toPascalCase } from 'utils/helpers/string'

// Components
import * as Styled from './styled'
import { Aligner, Body, DropdownMenu, Icon, IconButton } from 'heeds-ds'
import { ContentBox } from 'components'

type Props = {
  downloadPDFModel: TDownloadPDFModel
  handleAddModel: () => void
  handleEditModel: (modelIndex: number) => void
  onRemoveModel: TRemoveModel
  selectedModel: number
  setSelectedModel: (modelId: number) => void
  workoutRoutine?: IWorkoutRoutine
  workoutModels?: IWorkoutModelData[]
}

const WorkoutRoutineInfo: FC<Props> = ({
  downloadPDFModel,
  handleAddModel,
  handleEditModel,
  onRemoveModel,
  selectedModel,
  setSelectedModel,
  workoutModels,
  workoutRoutine,
}) => {
  const theme = useContext(ThemeContext)

  const hasModels = workoutModels && workoutModels.length > 0

  const getMuscleGroups = useCallback((model: IWorkoutModelData) => {
    const muscleSet = new Set()
    model.workout_set?.forEach((set) =>
      set.exercises.forEach((setToExercise) =>
        muscleSet.add(toPascalCase(setToExercise?.exercise?.muscle)),
      ),
    )

    return Array.from(muscleSet).join(' / ')
  }, [])

  const renderComments = useMemo(
    () =>
      workoutRoutine?.comments?.split('\n')?.map((row, index) => {
        return (
          <Styled.Comment key={index}>
            {row} <br />
          </Styled.Comment>
        )
      }),
    [workoutRoutine?.comments],
  )

  const renderWorkoutModels = useMemo(
    () =>
      workoutModels?.map((model, modelIndex) => {
        return (
          <Styled.ModelTag
            key={model.id}
            selected={selectedModel === modelIndex}
            onClick={() => setSelectedModel(modelIndex)}
          >
            <Aligner flex="column" gap="0.8rem">
              <Body type="copy4" weight={700}>
                {model.name.toUpperCase()}
              </Body>

              <Body type="copy5" color={theme.colors.text.subdued}>
                {getMuscleGroups(model)}
              </Body>
            </Aligner>

            <DropdownMenu
              items={[
                {
                  icon: 'pictureAsPdf',
                  label: 'PDF do treino',
                  onClick: () => downloadPDFModel(model.id),
                },
                {
                  icon: 'edit',
                  label: 'Editar',
                  onClick: () => handleEditModel(modelIndex),
                },
                {
                  icon: 'delete',
                  label: 'Excluir',
                  onClick: () => onRemoveModel(model.id, model.workout_routine),
                  color: theme.colors.text.critical,
                },
              ]}
            >
              <IconButton iconName="moreVertical" size="small" />
            </DropdownMenu>
          </Styled.ModelTag>
        )
      }),
    [
      downloadPDFModel,
      getMuscleGroups,
      handleEditModel,
      onRemoveModel,
      selectedModel,
      setSelectedModel,
      theme.colors.text.critical,
      theme.colors.text.subdued,
      workoutModels,
    ],
  )

  return (
    <React.Fragment>
      <Styled.RelativeDiv>
        <Styled.ScrollContainer>
          {workoutRoutine?.comments && (
            <ContentBox
              collapsible
              startsOpen
              title="Comentário sobre a rotina"
            >
              {renderComments}
            </ContentBox>
          )}

          <Aligner gap="16px" flex="column">
            {hasModels && renderWorkoutModels}
          </Aligner>
        </Styled.ScrollContainer>

        <Styled.FadeFooter></Styled.FadeFooter>
      </Styled.RelativeDiv>

      {hasModels && (
        <Styled.AddModelButton
          onClick={handleAddModel}
          track={buttonClickTracking}
          trackName="navigate_to_create_or_edit_workout_models"
        >
          <Icon iconName="add" color={theme.colors.interactive.default} />
          Adicionar Treino
        </Styled.AddModelButton>
      )}
    </React.Fragment>
  )
}

export default WorkoutRoutineInfo
