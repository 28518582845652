// Libraries
import styled from 'styled-components'

// Components
import { Aligner } from 'heeds-ds'

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 1.6rem 2.4rem 14.8rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    align-items: center;
    padding: 10.8rem 4rem 7.4rem;
    position: relative;
  }
`

export const FormScrollArea = styled.div`
  max-height: 100%;
  overflow-y: auto;
  margin-bottom: 10rem;
`

export const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    gap: 0;
    width: 79rem;
  }
`

export const Header = styled(Aligner).attrs({
  align: 'flex-end',
  justify: 'space-between',
})`
  backdrop-filter: blur(1.5px);
  height: 8.4rem;
  position: fixed;
  right: 4rem;
  top: 0;
  width: calc(100vw - 17.2rem);
  z-index: 2;
`
